

































import {Component, Ref, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ProductFeatureType, {ProductFeatureTypeEnum} from "@/models/ProductFeatureType";
import ProductFeatureTypeService from "@/services/ProductFeatureTypeService";

@Component
export default class ProductFeatureView extends Vue {
    @Ref() readonly form!: HTMLFormElement
    productFeatureType: ProductFeatureType = new ProductFeatureType()
    loading: boolean = false

    get productFeatureTypes() {
        return Object.keys(ProductFeatureTypeEnum)
    }

    created() {
        this.refresh()
    }

    refresh() {
        ProductFeatureTypeService.getProductFeatureType(this, Number(this.$route.params.productFeatureTypeId))
    }

    typeToName(type: ProductFeatureTypeEnum) {
        return ProductFeatureTypeService.typeToName(type)
    }

    updateProductFeatureType() {
        if (this.form.validate()) {
            getModule(DialogModule).showDialog(new Dialog(
                "Aviso",
                "¿Está seguro de actualizar la característica?",
                () => ProductFeatureTypeService.patchProductFeatureType(this, Number(this.$route.params.productFeatureTypeId), this.productFeatureType)
            ))
        }
    }
}
